import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toc"
export default class extends Controller {
  connect() {
    this.render()
  }

  // inspired by https://www.freecodecamp.org/news/how-to-make-a-dynamic-table-of-contents-in-javascript/
  render() {
    // setup aside with ul
    const aside = document.getElementById("toc");
    const ul = aside.getElementsByTagName("ul")[0];

    // fetch all h3 headings
    const headings = Array.from(this.element.getElementsByTagName("h3"));
    headings.map((heading, index) => {
        const id = `toc${index}`
        heading.setAttribute("id", id);
        const anchorElement = `<a href="#${id}">${heading.textContent}</a>`;
        const keyPointer = `<li>${anchorElement}</li>`;
        ul.insertAdjacentHTML("beforeend", keyPointer);
    });
  
    // set active section
    const tocAnchors = aside.querySelectorAll("a");
    const obFunc = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const index = headings.indexOf(entry.target);
                tocAnchors.forEach((tab) => {
                    tab.classList.remove("active");
                });
                tocAnchors[index].classList.add("active");
                tocAnchors[index].scrollIntoView({
                    block: "nearest",
                    inline: "nearest"
                });
            }
        });
    };

    //
    const obOption = {
        rootMargin: "-30px 0% -77%",
        threshold: 1
    };

    //
    // const observer = new IntersectionObserver(obFunc, obOption);
    // headings.forEach((hTwo) => observer.observe(hTwo));
  }
}
