import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "linkedin", "github", "portfolio", "other", "linkAlert" ]

  connect () {
    this.element.addEventListener("turbo:submit-start", e => this.validate(e))
    this.element.addEventListener("turbo:submit-end", e => this.scroll(e))
  }

  validate (event) {
    if (!this.linkedinTarget.value && !this.githubTarget.value && !this.portfolioTarget.value && !this.otherTarget.value) {
      this.linkAlertTarget.classList.remove("d-none")
      event.detail.formSubmission.stop()
      return
    }
  }

  scroll (event) {
    location.hash = "#new_job_application"
  }
}
