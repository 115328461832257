import { Controller } from "@hotwired/stimulus"

// Using ES6 import syntax
import hljs from 'highlight.js/lib/core'
import ruby from 'highlight.js/lib/languages/ruby'
import javascript from 'highlight.js/lib/languages/javascript'
import yaml from 'highlight.js/lib/languages/yaml'
import ini from 'highlight.js/lib/languages/ini'
import shell from 'highlight.js/lib/languages/shell'
import bash from 'highlight.js/lib/languages/bash'

// Then register the languages you need
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('yaml', yaml);
hljs.registerLanguage('ini', ini);
hljs.registerLanguage('shell', shell);
hljs.registerLanguage('bash', bash);

export default class extends Controller {
  connect() {
    hljs.highlightAll()
  }
}
