console.log('Vite ⚡️ Rails')

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import { Turbo } from "@hotwired/turbo-rails"

// Bootstrap
import "bootstrap"

// animate on scroll
import AOS from "aos"
window.AOS = AOS

// front theme code
import {HSCore} from "../vendor/hs.core.js"
window.HSCore = HSCore

import HSHeader from "../vendor/hs-header/src/js/hs-header.js"
window.HSHeader = HSHeader

// import HSMegaMenu from "../vendor/hs-mega-menu/src/js/hs-mega-menu.js"
// window.HSMegaMenu = HSMegaMenu

import HSShowAnimation from "../vendor/hs-show-animation/src/js/hs-show-animation.js"
window.HSShowAnimation = HSShowAnimation

import HSGoTo from "../vendor/hs-go-to/src/js/hs-go-to.js"
window.HSGoTo = HSGoTo

// highlightjs
import hljs from 'highlight.js'

// stimulus controllers
import "../controllers"