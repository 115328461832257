import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // INITIALIZATION OF HEADER
    // =======================================================
    new HSHeader('#header').init()

    // INITIALIZATION OF MEGA MENU
    // =======================================================
    // new HSMegaMenu('.js-mega-menu', {
    //   desktop: {
    //     position: 'left'
    //   }
    // })

    // INITIALIZATION OF SHOW ANIMATIONS
    // =======================================================
    new HSShowAnimation('.js-animation-link')

    // INITIALIZATION OF GO TO
    // =======================================================
    new HSGoTo('.js-go-to')

    // INITIALIZATION OF AOS
    // =======================================================
    AOS.init({
      duration: 650,
      once: true
    });
  }
}
